import React from 'react';

import { Layout } from '../components/layout';
import { SEO } from '../components/seo';
import { Content } from '../components/content';

export default function() {
  const title = 'Prótesis dentales';

  return (
    <Layout>
      <SEO title={title} />
      <h1>{title}</h1>
      <Content>
        <p>
          Habitualmente se presentan en las clínicas pacientes que han perdido gran parte de su dentadura ocasionando
          como consecuencia de ello problemas funcionales y estéticos.
        </p>
        <p>
          El factor estético genera una alta expectativa en cuanto a los resultados que se obtendrán luego de haber
          culminado el tratamiento requerido. La mayor preocupación del paciente parcial o totalmente desdentado que
          recibirá una prótesis es el hecho que dicha estructura se adapte a su cavidad bucal y no resulte molesta. Es
          por ello que es uno de nuestros pilares fundamentales un buen diagnóstico y una exhaustiva planificación del
          tratamiento y prótesis a utilizar usando los mejores materiales y de esta manera satisfacer a nuestros
          pacientes estética y funcionalmente.
        </p>
        <h2>Preguntas frecuentes</h2>
        <h3>¿Qué tipo de prótesis dentales existen?</h3>
        <ul>
          <li>
            Prótesis fijas: son aquellas que el paciente no puede quitárselas. Entre ellas podemos encontrar las coronas
            aplicadas sobre pernos.
          </li>
          <li>
            Prótesis Removibles: son aquellas que el paciente a su voluntad, puede quitárselas e higienizar. Estas
            pueden ser: parciales o totales.
          </li>
        </ul>
        <h3>¿Por qué deben reponerse los dientes perdidos?</h3>
        <p>
          Una de las causas es para mantener la arquitectura de los arcos dentales. Toda vez que una pieza dental se
          pierde, las piezas adyacentes o cercanas se inclinan hacia el espacio y los opuestos tienden a crecer,
          perdiendo así el equilibrio fisiológico y el estado de salud.
        </p>
        <p>Otra de las razones es para mantener la función masticatoria.</p>
        <p>
          Finalmente la cuestión estética. Hoy por razones sociales es prácticamente inadmisible una dentadura con
          deficiencias.
        </p>
        <h3>
          ¿Puede un laboratorio de mecánica dental realizar una prótesis sin intervención del profesional odontólogo?
        </h3>
        <p>
          NO. De ninguna manera puede un mecánico dental realizar una prótesis dental sin intervención del profesional
          odontólogo. El mecánico no está habilitado técnicamente para diagnosticar y mucho menos para realizar
          tratamientos. El mecánico es un asistente técnico del odontólogo. Debe realizar las tareas que este le
          encomiende y ajustarse a sus requerimientos. La intervención de un odontólogo en la construcción de una
          prótesis garantiza confiabilidad en el uso de la misma.
        </p>
      </Content>
    </Layout>
  );
}
